'use client'

import { signInAndCreate, getConfigNames } from "@/app/actions/lol"
import Button from "react-bootstrap/Button"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import NavDropdown  from "react-bootstrap/NavDropdown"
import Container from "react-bootstrap/Container"
import Image from "react-bootstrap/Image"
import { useSession } from 'next-auth/react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { byPrefixAndName } from "@awesome.me/kit-180e59b8f3/icons"
import Link from "next/link"
import { signIn } from "next-auth/webauthn"
import { useEffect, useState } from "react"

export default function Navigation() {
  const session = useSession();
  const [path, setPath] = useState('')
  const [configNames, setNames] = useState<object[]>([])
  const updateNames = () => {
    getConfigNames().then(conf =>setNames(conf))
  }
  useEffect(() => { updateNames(); setPath(window.location.pathname) },[])
  return (
    <>
    <Navbar variant={"tabs"} collapseOnSelect expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="/">BoostLoL</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav fill navbar justify activeKey={path} variant="pills" defaultActiveKey="/"  className="me-auto">
          {session.status == "authenticated" && <><Nav.Item>
              <NavDropdown title="Configs" id="collasible-nav-dropdown">
                {configNames?.map((data: any) => <NavDropdown.Item key={data.apiKey} href={"/?key="+data.apiKey}>{data.name}</NavDropdown.Item>)}
                <NavDropdown.Divider />
                <NavDropdown.Item href="/profile">Create</NavDropdown.Item>
              </NavDropdown>
            </Nav.Item>
            <Nav.Item><Nav.Link  href="/profile">Profile</Nav.Link></Nav.Item> 
            <Nav.Item><Nav.Link href="/faq">FAQ</Nav.Link></Nav.Item>
          </>}
          <NavDropdown title="Extensions">
            <NavDropdown.Item href="https://chromewebstore.google.com/detail/boostlol-commander/gahaabajfeggdbieiccbkajbeebikpkg?authuser=0&hl=en">Chrome</NavDropdown.Item>
            <NavDropdown.Item href="https://microsoftedge.microsoft.com/addons/detail/bjlcgfbmljknbckbfdclbapjinblgece">Edge</NavDropdown.Item>
          </NavDropdown>
          </Nav>
      
          <Nav>
            {session.status == "unauthenticated" && (
              <Container>
                  <Button onClick={() => signInAndCreate("google")} variant="dark" type="submit">
                    <FontAwesomeIcon icon={byPrefixAndName.fab['google']} title="Signin with Google" />
                  </Button>
                  <Button onClick={() => signInAndCreate("github")} variant="dark" type="submit">
                    <FontAwesomeIcon icon={byPrefixAndName.fab['github']} title="Signin with Github" />
                  </Button>
                  <Button onClick={() => signIn("passkey")}>
                   <FontAwesomeIcon icon={byPrefixAndName.fad['key']} title="Signin with Passkey" />
                  </Button>
              </Container>
            )}
            {session.status == "authenticated" && (
              <Container>
                <Link href={'/api/auth/signout'}> <Button variant="dark" type="submit">Logout</Button></Link>
                <Link href={'/profile'}><Image fluid roundedCircle width={55} src={session.data.user?.image??''} alt={session.data.user?.name??''} /></Link>
            </Container>
                       )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </>
  )
} 